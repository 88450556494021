@font-face {
  font-family: "Akkurat";
  font-weight: 100;
  src: url("./assets/fonts/AkkuratLLWeb-Thin.woff") format("woff"), url("./assets/fonts/AkkuratLLWeb-Thin.woff2") format("woff2");
}

@font-face {
  font-family: "Akkurat";
  font-weight: 100;
  font-style: italic;
  font-family: "AkkuratLLWeb-ThinItalic";
  src: url("./assets/fonts/AkkuratLLWeb-ThinItalic.woff") format("woff"), url("./assets/fonts/AkkuratLLWeb-ThinItalic.woff2") format("woff2");
}

@font-face {
  font-family: "Akkurat";
  font-weight: 300;
  src: url("./assets/fonts/AkkuratLLWeb-Light.woff") format("woff"), url("./assets/fonts/AkkuratLLWeb-Light.woff2") format("woff2");
}

@font-face {
  font-family: "Akkurat";
  font-weight: 300;
  font-style: italic;
  src: url("./assets/fonts/AkkuratLLWeb-LightItalic.woff") format("woff"), url("./assets/fonts/AkkuratLLWeb-LightItalic.woff2") format("woff2");
}

@font-face {
  font-family: "Akkurat";
  font-weight: 400;
  src: url("./assets/fonts/AkkuratLLWeb-Regular.woff") format("woff"), url("./assets/fonts/AkkuratLLWeb-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Akkurat";
  font-weight: 400;
  font-style: italic;
  src: url("./assets/fonts/AkkuratLLWeb-Italic.woff") format("woff"),url("./assets/fonts/AkkuratLLWeb-Italic.woff2") format("woff2");
}

@font-face {
  font-family: "Akkurat";
  font-weight: 700;
  src: url("./assets/fonts/AkkuratLLWeb-Bold.woff") format("woff"), url("./assets/fonts/AkkuratLLWeb-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Akkurat";
  font-weight: 700;
  font-style: italic;
  src: url("./assets/fonts/AkkuratLLWeb-BoldItalic.woff") format("woff"), url("./assets/fonts/AkkuratLLWeb-BoldItalic.woff2") format("woff2");
}

@font-face {
  font-family: "Akkurat";
  font-weight: 900;
  src: url("./assets/fonts/AkkuratLLWeb-Black.woff") format("woff"), url("./assets/fonts/AkkuratLLWeb-Black.woff2") format("woff2");
}

@font-face {
  font-family: "Akkurat";
  font-weight: 900;
  font-style: italic;
  src: url("./assets/fonts/AkkuratLLWeb-BlackItalic.woff") format("woff"), url("./assets/fonts/AkkuratLLWeb-BlackItalic.woff2") format("woff2");
}

* { 
  box-sizing: border-box; 
  -webkit-tap-highlight-color: transparent; 
  user-select: none;
}

a {
  color: inherit;
}

:not(.scroll) {
  scrollbar-width: none;
}

:not(.scroll)::-webkit-scrollbar {
  background: transparent;
  width: 0px;
}

html {
  width: 100%;
  height: 100%;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Akkurat', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: font-size .1s;
  overflow: hidden;
  background-color: #000;
  width: 100%;
  height: 100%;
}

#root {
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
}

#root.preview .react-transform-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

p {
  line-height: 1.4em;
  margin-bottom: 1em;
  margin-top: 0;
}

/* Page transitions */

.fade-enter {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 300ms;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

/* Media element transitions */

img, video {
  transition: opacity 600ms;
  opacity: 1;
}

img.loading, video.loading {
  opacity: 0;
}

img.loaded, video.loaded {
  opacity: 1 !important;
}

/* Preview */

.showInPreview, .showInPreview * {
  pointer-events: all !important;
}

/* Photoswipe */

.pswp__counter, .pswp__button--zoom {
  display: none !important;
}

.pswp.no_swipe .pswp__container {
  transform: translate3d(0, 0, 0) !important;
}

.pswp.no_swipe .pswp__counter {
  display: none !important;
}

.pswp__top-bar {
  background: none !important;
}

.pswp__img {
  object-fit: contain !important;
}

.pswp__default-caption {
  text-align: left !important;
  font-size: 1em !important;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.85), transparent);
  bottom: 0 !important;
  padding: 40px !important;
  pointer-events: none !important;
}

.pswp__button--close {
  position: absolute;
  top: 2.5vw;
  right: 2.5vw;
  width: 3vw !important;
  height: 3vw !important;
  border-radius: 50% !important;
  border: none;
  background-color: #fff !important;
  background-image: url('/src/assets/icon_close.svg') !important;
  background-position: 50% 50% !important;
  background-size: 2vw !important;
  background-repeat: no-repeat !important;
  opacity: 1 !important;
}

.pswp__hide-on-close {
  opacity: 1 !important;
}

.pswp__button--close svg {
  display: none;
}

.pswp__caption__center {
  text-align: center !important;
  max-width: 80% !important;
}

.react-transform-wrapper {
  max-height: 100vh !important;
}

.pswp--has_mouse .pswp__button--arrow {
  display: none;
}

/* Lexical WYSIWYG editor */
.editor_textBold { 
  font-weight: bold; 
} 
.editor_textItalic { 
  font-style: italic; 
} 
.editor_textUnderline { 
  text-decoration: underline; 
} 
.editor_textStrikethrough { 
  text-decoration: line-through; 
}

.editor_nestedListItem {
  list-style-type: none;
}
.editor_nestedListItem:before,
.editor_nestedListItem:after {
  display: none;
}

/* Carousel */
.carousel.carousel-slider .control-arrow:hover {
  background: none !important;
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
  opacity: 1 !important;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
  background-color: rgba(255, 255, 255, 0.02);
}

.carousel .control-prev.control-arrow {
  left: 15px !important;
}

.carousel .control-next.control-arrow {
  right: 15px !important;
}

/* Flip book */
.stf__parent {
  display: none;
  background-size: cover;
}

.stf__innerShadow, .stf__outerShadow {
  background: transparent !important;
}

.stf__wrapper:not(.--portrait) .stf__item:not(:last-of-type).--left::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-right: 0;
  /*box-shadow: inset -7px 0 30px -7px rgba(0, 0, 0, 0.4);*/
}

.stf__wrapper:not(.--portrait) .--right:not(:first-of-type)::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-left: 0;
  /*box-shadow: inset 7px 0 30px -7px rgba(0, 0, 0, 0.4);*/
}

.page-footer {
  text-align: right;
}

.hard {
  background-color: hsl(35, 50, 90);
  border: solid 1px hsl(35, 20, 50);
}

.page-cover {
  background-color: hsl(35, 45, 80);
  color:  hsl(35, 35, 35);
  border: solid 1px hsl(35, 20, 50);
}